import { DeleteForever } from '@mui/icons-material';
import { Alert, Grid, IconButton, Tab, Tabs, Tooltip } from '@mui/material';
import colors from 'assets/theme/base/colors';
import LineChart from 'components/charts/lineChart'
import BarChart from 'components/charts/barChart';
import PieChart from 'components/charts/pieChart';
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import { useDispatch } from 'react-redux';
import { deleteArchiver } from 'redux/actions/archivers';
import { convertBytes } from 'utils/helpers';
import { getAvg } from 'utils/helpers';
import { Settings as SettingsIcon } from "@mui/icons-material";
import Modal from './infoDialog';
import { updateArchive } from 'redux/actions/archiver';
import { updateArchiver } from 'redux/actions/archivers';
import { updateTranscoders } from 'redux/actions/transcoders';
import { updateStreamer } from 'redux/actions/streamers';
import { updateVODs } from 'redux/actions/VODs';
import { DeletePermission } from 'pages/settings/Admins/constants';
import WithPermission from 'components/withPermission/WithPermission';
import { EditServerSettingsPermission } from 'pages/settings/Admins/constants';
import styles from './styles.module.css'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import moment from 'moment';
import { valueOrNA } from 'utils/helpers';

export default function HostCard({
    id,
    host,
    name,
    actionText,
    actionClick,
    systemInfo,
    deleteCB,
    type,
    permissionKey,
    widget,
    widgetToAdd
}) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [cpuSeries, setCpuSeries] = useState([])
    const [memorySeries, setMemorySeries] = useState([])
    const [hardMemory, setHardMemory] = useState([])
    const [systemFullInfo, setSystemFullInfo] = useState(null)
    const [tab, setTab] = useState(0)
    const [gpusInfo, setAllGPUInfo] = useState(null)
    const [openModal, setOpenModal] = useState({ bool: false })
    const isAlive = useMemo(() => { return systemInfo?.advices?.every(item => item?.adviceKey !== "unreachable") ?? true }, [systemInfo?.advices])

    const systemToChartData = (data) => {
        const cpuData = []
        const networkData = []
        for (let i = 0; i < data.length; i++) {
            if (!data[i].currentLoad) continue;
            const averageLoad = getAvg(data[i].currentLoad.map(item => item.load))
            cpuData.push([data[i].date, averageLoad.toFixed(1)])
            networkData.push([data[i].date,])
        }
        const hardMem = [+data?.[data.length - 1]?.hardMemory?.total - +data?.[data.length - 1]?.hardMemory?.free, +data?.[data.length - 1]?.hardMemory?.free]
        const memoryData = [data?.[data.length - 1]?.memory?.active, +data?.[data.length - 1]?.memory?.total - +data?.[data.length - 1]?.memory?.active]
        const gpuInfo = data[data.length - 1]?.GPUInfo && { 'attached Gpus': data[data.length - 1]?.GPUInfo?.attachedGpus, 'cuda Version': data[data.length - 1]?.GPUInfo?.cudaVersion, 'driver Version': data[data.length - 1]?.GPUInfo?.driverVersion }
        const fullInfo = { systemInfo: data[data.length - 1]?.systemInfo, cpu: data[data.length - 1]?.cpu, temp: data[data.length - 1]?.temp, gpuInfo }
        return { cpuData, memoryData, fullInfo, hardMem }
    }

    const handleDelete = () => {
        deleteCB && deleteCB(id)
    }

    const getActionBasedType = (type) => {
        switch (type) {
            case 'Archiver':
                return updateArchiver
            case 'Transcoder':
                return updateTranscoders
            case 'Streamer':
                return updateStreamer
            case 'VODs':
                return updateVODs
            default:
                throw new Error('host type not specified')
        }
    }

    const handleUpdate = async function (values) {
        const action = getActionBasedType(type)
        await dispatch(action({ ...values, ...this.items, _id: id }))
        setOpenModal({
            bool: false
        });
    }

    const handleOpenModal = (item) => {
        setOpenModal({
            bool: true,
            onSave: handleUpdate.bind(item),
            modalValues: item,
            type
        });
    }

    useEffect(() => {

        const { cpuData, memoryData, fullInfo, hardMem } = systemToChartData(systemInfo.historyStatus)
        setCpuSeries([
            {
                name: 'CPU load',
                data: cpuData
            }
        ])
        setMemorySeries(memoryData)
        setHardMemory(hardMem)
        setSystemFullInfo(fullInfo)

        const gpuInfo = systemInfo?.historyStatus?.[systemInfo?.historyStatus?.length - 1]?.GPUInfo
        setAllGPUInfo(gpuInfo?.gpus)
    }, [systemInfo])

    const handleTabChange = (e, val) => {
        setTab(val)
    }

    return <MDBox
        mx={2}
        my={2}
        py={3}
        px={2}
        variant="gradient"
        borderRadius="lg"
        coloredShadow="info"
        sx={{
            height: "fit-content",
            position: "relative",
        }}
    >
        {!isAlive && <MDBox py={2}>
            <Alert icon={<ReportProblemIcon fontSize="inherit" />} severity='error' >{t("advices.unreachableLong")} {moment?.duration(systemInfo?.historyStatus?.[systemInfo?.historyStatus?.length - 1].date - Date.now(), 'milliseconds').humanize(true)} ({moment(systemInfo?.historyStatus?.[systemInfo?.historyStatus?.length - 1].date).format("YYYY/MM/DD HH:mm:ss")})</Alert>
        </MDBox>}
        {systemInfo?.advices?.length && isAlive && <MDBox mt={window.innerWidth < 600 ? 4 : 0} mb={window.innerWidth < 600 ? -6 : 0} py={2}>
            {systemInfo?.advices?.map((item) => {
                return <Alert icon={item.severity === "error" ? <ReportProblemIcon fontSize="inherit" /> : <InfoIcon fontSize="inherit" />} severity={item.severity} >
                    {t(`advices.${item.adviceKey}`, { name: item.name, ...(item.vars ?? {}) })}
                </Alert>
            })}
        </MDBox>
        }
        <MDButton
            className={styles.nameBtn}
            color={isAlive ? "success" : "error"}
            variant="gradient"
        >
            <span>{name}</span>
            {!widget && <WithPermission disable={true} permissionsList={[DeletePermission]} permissionKey={permissionKey} >
                <Tooltip title={!deleteCB ? t('forbidDelete') : t('dblClick')} >
                    <span>
                        <IconButton onDoubleClick={handleDelete} style={deleteCB ? {} : { pointerEvents: 'none' }} disabled={!deleteCB} color={!isAlive ? "default" : "error"} size='small'><DeleteForever /> </IconButton>
                    </span>
                </Tooltip>
            </WithPermission>}
        </MDButton>
       {!widgetToAdd && <MDBox className={styles.settings}>
            <WithPermission disable={true} permissionsList={[EditServerSettingsPermission]} permissionKey={permissionKey} >
                <MDButton
                    color="info"
                    variant="outlined"
                    onClick={() => { handleOpenModal(systemInfo, id) }}
                >
                    <SettingsIcon />
                </MDButton>
            </WithPermission>
            {!widget && <MDButton
                className={styles.actionBtn}
                color="info"
                onClick={() => { actionClick({ id, name, host }) }}
            >
                {actionText}
            </MDButton>}
        </MDBox>}


        {type === 'Transcoder' &&
            <Tabs className={styles.tabs} value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label={t("Server")} />
                {!widgetToAdd && <Tab label="GPU(s)" />}
            </Tabs>
        }
        {
            tab === 0 ?
                <ServerInfo widgetToAdd={widgetToAdd} type={type} hardMemory={hardMemory} memorySeries={memorySeries} cpuSeries={cpuSeries} systemFullInfo={systemFullInfo} />
                :
                <GPUInfo onChartClick={(label) => { actionClick({ id, name, host, label }) }} gpusInfo={gpusInfo} />
        }
        <Modal openModal={openModal} setOpenModal={setOpenModal} />
    </MDBox>
}





const ServerInfo = ({widgetToAdd, memorySeries, cpuSeries, systemFullInfo, hardMemory, type }) => {
    const { t } = useTranslation()
    return <Grid container className={type !== 'Transcoder' && styles.tabs} style={{ overflow: "hidden"}}>
        {!widgetToAdd && <Grid item xs={12} md={!!hardMemory[0] && !!hardMemory[1] ? 7 : 8} className={styles.infoCont}>
            <MDBox sx={{ display: 'flex', width: '100%', overflow: "auto" }}>
                <MDBox px={2} sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)',  }}>
                    {!widgetToAdd && systemFullInfo && Object.entries(systemFullInfo?.systemInfo || []).map(([key, value]) => (
                        <>
                            <div style={{ fontSize: '14px' }}>

                                {t(key)}:
                            </div>
                            <div style={{ fontSize: '14px' }} >
                                <strong>{value}</strong>
                            </div>
                        </>
                    ))}
                    {systemFullInfo && <>
                        <div style={{ fontSize: '14px' }}  >
                            {t('CPU_Temp')}:
                        </div>
                        <div style={{ fontSize: '14px' }}  >
                            <strong style={{ color: systemFullInfo.temp.main < 40 ? colors.success.main : systemFullInfo.temp.main < 80 ? colors.warning.main : colors.error.main }}>
                                {systemFullInfo.temp.main} °C
                            </strong>
                        </div>
                    </>}
                </MDBox>
                <MDBox px={2} sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    {systemFullInfo && Object.entries(systemFullInfo?.cpu || []).map(([key, value]) => (
                        <>
                            <div style={{ fontSize: '14px' }} >
                                {t(key)}:
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                <strong>{value}</strong>
                            </div>
                        </>
                    ))}
                </MDBox>
                {systemFullInfo && systemFullInfo?.gpuInfo && <MDBox px={2} sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(5, 1fr)' }}>
                    {Object.entries(systemFullInfo?.gpuInfo || []).map(([key, value]) => (
                        <>
                            <div style={{ fontSize: '14px' }} >
                                {t(key)}:
                            </div>
                            <div style={{ fontSize: '14px' }} >
                                <strong>{value}</strong>
                            </div>
                        </>
                    ))}
                </MDBox>}
            </MDBox>
        </Grid>}
        {
            // !!hardMemory?.[0] && !!hardMemory?.[1] && 
        }
        <Grid container item xs={12} md={!!hardMemory[0] && !!hardMemory[1] ? 5 : 4} className={styles.pieCharts}>
            {!widgetToAdd && !!hardMemory[0] && !!hardMemory[1] && <Grid item xs={12} md={6}>
                <PieChart title={t('Space')} series={hardMemory} deltaL={1} />
            </Grid>
            }
            {<Grid item xs={12} md={6}>
                <PieChart title={t('RAM')} series={memorySeries} />
            </Grid>}
        </Grid>
        <Grid item xs={12}>
            <LineChart series={cpuSeries} />
        </Grid>
    </Grid>
}


const GPUInfo = ({ gpusInfo, onChartClick }) => {
    const { t } = useTranslation()
    const [activeGpu, setActiveGpu] = useState(0)
    const [chartLabels, setChartLabels] = useState([])
    const [chartSeries, setChartSeries] = useState([])
    const handleTabChange = (e, v) => {
        setActiveGpu(v)
    }

    useEffect(() => {
        if (gpusInfo?.[activeGpu]?.processes) {
            const labels = []
            const series = []
            gpusInfo[activeGpu].processes.forEach(item => {
                labels.push(`${item.processName}`)
                series.push(item.usedMemory)
            })
            setChartSeries(series)
            setChartLabels(labels)
        }
    }, [activeGpu])

    if (!gpusInfo) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: "100%", width: '100%' }} >No Information to show</div>
    }

    return <Grid container>
        <Grid item xs={12}>
            <Tabs value={activeGpu} onChange={handleTabChange} aria-label="basic tabs example">
                {gpusInfo.map((gpu, i) => <Tab key={gpu.uuid} label={`${i + 1}: ${gpu.name}`} />)}
            </Tabs>
        </Grid>
        <Grid item xs={12} md={4} className={styles.infoCont}>
            <MDBox px={4}>
                <div style={{ textTransform: 'capitalize' }} >{t("Brand")}: <strong>{gpusInfo?.[activeGpu]?.brand}</strong></div>
                <div style={{ textTransform: 'capitalize' }} >{t("Fan speed")}: <strong>{gpusInfo?.[activeGpu]?.fanSpeed}</strong></div>
                <div style={{ textTransform: 'capitalize' }} >{t("Power/limit")}: <strong>{`${valueOrNA(gpusInfo?.[activeGpu]?.power?.power_draw)}/${valueOrNA(gpusInfo?.[activeGpu]?.power?.power_limit || gpusInfo?.[activeGpu]?.power?.current_power_limit)}`}</strong></div>
                <div style={{ textTransform: 'capitalize' }} >{t("GPU Temp")}: <strong style={{ color: parseInt(gpusInfo?.[activeGpu]?.temperature?.gpu_temp) < 70 ? colors.success.main : parseInt(gpusInfo?.[activeGpu]?.temperature?.gpu_temp) < 90 ? colors.warning.main : colors.error.main }}>{parseInt(gpusInfo?.[activeGpu]?.temperature?.gpu_temp)} °C</strong></div>
            </MDBox>
        </Grid>
        <Grid item xs={12} md={4}>
            <PieChart series={[gpusInfo?.[activeGpu]?.fbMemoryUsage?.used, gpusInfo?.[activeGpu]?.fbMemoryUsage?.free]} />
        </Grid>
        <Grid item xs={12}>
            <BarChart onChartClick={onChartClick} series={chartSeries} labels={chartLabels} />
        </Grid>
        {/* <Grid item xs={12}>
                <LineChart series={cpuSeries} />
            </Grid> */}
    </Grid>
}