import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import VideoJS from "./Player/VideoJS";
import React, { useCallback, useEffect, useState } from "react";
import { getToken } from "utils";
import MDButton from "./MDButton";

export default function ModalPlayer({ primaryUrl, openModal, setOpenModal, headerComponent, leftComponent, rightComponent, downComponent }) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(openModal?.modalValues?.url ?? "");
  }, [openModal?.bool]);

  const playerRef = React.useRef(null);

  useEffect(() => {
    playerRef?.current?.handleSrc_(
      [
        {
          src: primaryUrl,
          type: 'application/x-mpegURL',
          headers: {
            Authorization: getToken()
          }
        },
      ],
      true
    );
    if (playerRef.current?.vhs?.xhr) {
      playerRef.current.vhs.xhr.beforeRequest = function (options) {
        options.headers = { ...options.headers, Authorization: getToken() }
        return options;
      };
    }
  }, [primaryUrl])

  const videoJsOptions = {
    autoplay: true,
    preload: 'auto',
    controls: false,
    responsive: false,
    controls: true,
    fill: true,
    liveui: false,
    // controlBar: false,
    // loadingSpinner: true,
    bigPlayButton: false,
    fluid: false,
    aspectRatio: '16:9',
    sources: [{
      src: url,
      type: 'application/x-mpegURL',
    }],
    tracks: openModal?.modalValues?.item?.subtitles?.map?.((item, index) => ({ ...item, label: item.title || `Track ${index}`, srclang: item.language || `Caption ${index + 1}`, src: `${openModal?.modalValues?.item.VODServerInfo.host}${item.uri}` })) || [],
  };

  const selectSubs = (item) => {
    const tracks = playerRef?.current?.textTracks();
    Array.from(tracks).forEach(sub => sub.mode = sub?.src?.includes?.(item.uri) ? "showing" : "disabled")
  }

  // const selectAudioss = (item) => {
  //   const tracks = playerRef?.current?.audioTracks();
  //   Array.from(tracks).forEach(sub => sub.enabled = sub?.id?.includes?.(item.id))
  //   console.log(Array.from(tracks).find(sub => sub?.id?.includes?.(item.id)))
  // }

  const handlePlayerReady = useCallback((player) => {
    playerRef.current = player;
  }, []);

  return (
    <MDDialog
      dialogHeight="fit-content"
      maxWidth="xl"
      fullWidth
      open={openModal?.bool}
      setOpen={setOpenModal}

    >
      <MDBox>
        <MDBox sx={{ display: 'flex', justifyContent: 'flex-end', maxWidth: '100%' }}>
          {/* <input value = {seekVal} onChange={(e)=>setSeekVal(e.target.value)} />
        <button onClick={()=>seek(seekVal)} > Seek</button> */}
          {headerComponent}
        </MDBox>
        {/* {url} */}
        <MDBox sx={{ display: 'flex' }} >
          {leftComponent}
          <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
          {rightComponent}
        </MDBox>
        <MDBox>
          {downComponent}
        </MDBox>
        <MDBox>
          {openModal?.modalValues?.item?.subtitles?.map?.(item => {
            return <MDButton variant="outlined" color="info" onClick={() => selectSubs(item)}>
              {item.title}
            </MDButton>
          })}
        </MDBox>

        {/* {audioTrackList?.map?.(item => {
          return <button onClick={() => selectAudioss(item)}>
            {item.label}
          </button>
        }
        )} */}

      </MDBox>
      <MDBox>
        {/* <video width="320" height="240" controls>
          <source src={url} type={'video/mp2t'} />
        </video> */}
      </MDBox>
    </MDDialog>
  );
}
