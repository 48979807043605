import { FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, Switch, } from "@mui/material";
import MDBox from "components/MDBox";
import MDDialog from "components/MDDialog";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getTariffs } from "redux/actions/tariffs";
import { DropzoneDialog } from "material-ui-dropzone";
import MDSelect from "components/MDSelect";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { AddOutlined, CloseRounded, Remove } from "@mui/icons-material";
import { usePrevious } from "utils/customHooks";
// import { resolutionsList } from "utils/constants";
import MDSelectMui from "components/MDSelectMui";
import WithPermission from "components/withPermission/WithPermission";
import { EditSourcePermission } from "pages/settings/Admins/constants";
import { checkPermission } from "utils/checkPermission";
import { AdvancedModePermission } from "pages/settings/Admins/constants";
import MDButton from "components/MDButton";
import { getStreamers } from "redux/actions/streamers";



export default function CreateStream({ openModal, setOpenModal, streamerInfo, handleCancel, handleConfirm }) {
    const dispatch = useDispatch()
    const uniqueNameError = 'Name must be unique for a streamer'
    const uniqueInputError = 'Input must be unique for a streamer'
    const { settings } = useSelector(store => store.settings)
    const resolutionsList = useSelector(store => store.settings.settings.resolutions)

    
    const serversList = useSelector(store => store?.streamers?.list)
    const initSelectedServer = {
        host: streamerInfo?.host || serversList[0]?.url,
        id: streamerInfo?.id || serversList[0]?._id,
        _id: streamerInfo?.id || serversList[0]?._id,
        name: streamerInfo?.name || serversList[0]?.name
    }
    const [selectedServer, setSelectedServer] = useState(initSelectedServer)
    const server = useSelector(store => store?.streams)
    const streams = server?.list?.filter(item => item.streamerId === (selectedServer?._id || selectedServer?.id))
    const handleChangeSelectedServer = (_, server) => {
        setSelectedServer({
            host: server.host,
            id: server._id,
            _id: server._id,
            name: server.name
        })
    }

    const schema = yup.object({
        name: yup.string()
            .required('field is required')
            .test('test', uniqueNameError, function (value) {
                if (openModal?.isUpdate) {
                    return streams.filter(item => item._id !== openModal?.modalValues?._id).every(item => item.name !== value)
                }
                return streams.every(item => item.name !== value)
            }),
        // input: yup.array().of(yup.object().shape({ 
        //     resolution: yup.object(),
        //     input: yup.string()
        //     .required('field is required')
        //     .test('test', uniqueInputError, function(value){
        //         if(openModal.isUpdate){
        //             return streams.filter(item=>item._id !== openModal?.modalValues?._id).every(item=>item.input !== value)
        //         }
        //             return streams.every(item=>item.input !== value)
        //     })})),
        command: yup.string()
            .required('field is required'),
    });
    const { t } = useTranslation()
    const [withCmd, setWithCmd] = useState(false)
    const [selectedResIds, setSelectedResIds] = useState([])


    const emptyInput = { input: "", resolution: {} }

    const [inputs, setInputs] = useState([emptyInput])

    const initValues = {
        name: openModal?.values?.name ?? "ff_",
        input: openModal.values?.input?.length ? openModal?.values?.input : [emptyInput],
        command: openModal.values?.command ?? "",
        resolution: openModal.values?.resolution?.length ? openModal.values.resolution : [],
        streamerInfo: initSelectedServer,
    };

    const { values, handleChange, touched, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: (values) => {
            if (withCmd) {
                const arr = values.command
                    .split('\n')
                    .map(item => item.trim())
                    .filter(item => item)
                // console.log({ ...values, withCmd, command: arr.join('\n') });
                const data = { ...values, withCmd, inputs, command: arr.join('\n'), streamerInfo: selectedServer }
                handleConfirm ? handleConfirm(data) : openModal?.onSave(data);
            } else {
                const data = { ...values, withCmd, inputs, streamerInfo: selectedServer }
                handleConfirm ? handleConfirm(data) : openModal?.onSave(data);
            }
            setOpenModal({ bool: false });
        },
    });

    const handleAddInputUrl = () => {
        setInputs([...inputs, emptyInput])
    }

    const handleRemoveInputUrl = (i) => {
        const inputsClone = JSON.parse(JSON.stringify(inputs))
        inputsClone.splice(i, 1)
        setInputs(inputsClone)
    }

    const handleInputsChange = (e, i) => {
        const inps = JSON.parse(JSON.stringify(inputs))
        inps[i].input = e.target.value;
        setInputs(inps)
    }

    const handleResolution = (ids, i) => {
        const res = resolutionsList.find(item => ids.value === item.id)
        // console.log(res, ids);
        const inps = JSON.parse(JSON.stringify(inputs))
        inps[i].resolution = res;
        setInputs(inps)
        // setSelectedResIds([ids[ids.length - 1]])
    }

    const prevName = usePrevious(values?.name)
    const prevInput = usePrevious(values?.input)

    useEffect(() => {
        if (withCmd) {
            let command = values.command
            if (prevName) { command = command.replaceAll(prevName, values?.name) }
            if (prevInput) command = command.replaceAll(prevInput, values?.input)
            setFieldValue('command', command)
        } else {
            const keywordsMapping = [
                { value: values.name, keyword: '{{Name}}' },
                { value: `${inputs.map((item) => `-i ${item.input}`).join('\n ')}`, keyword: '{{INPUTS}}' },
                { value: `${inputs.map((it, i) => `-map ${i}:v:0 -map ${i}:a:0`).join('\n ')}`, keyword: '{{MAPPING}}' },
                { value: `${inputs.map((it, i) => `-c:v:${i} copy -b:v:${i} ${it?.resolution?.bitrate} -maxrate ${it?.resolution?.maxRate} -minrate ${it?.resolution?.minRate} -bufsize ${it?.resolution?.bufSize}`).join('\n ')}`, keyword: '{{BITRATES}}' },
                { value: `${inputs.map((it, i) => `-c:a:${i} copy`).join('\n ')}`, keyword: '{{AUDIO}}' },
                { value: inputs.map((it, i) => `v:${i},a:${i},name:${it?.resolution?.name}`).join(' '), keyword: '{{STREAM_MAPPING}}' },
                { value: `/streams/${values.name}/%v/index.m3u8`, keyword: '{{OUTPUT}}' },

            ]
            let streamerCommand = settings.defaults.streamerCommand
            keywordsMapping.forEach((item) => {
                streamerCommand = streamerCommand.replaceAll(item.keyword, item.value)
            })
            setFieldValue('command', streamerCommand)
        }

    }, [values?.name, values?.input, withCmd, inputs])

    useEffect(() => {
        const initValues = {
            name: "",
            input: [emptyInput],
            command: "",
            resolution: [],
        };
        Object.keys(initValues).forEach((key) => {
            if (key === 'resolution') {
                let ids = openModal?.modalValues?.[key]?.map(item => item.id)
                setSelectedResIds(ids ?? [])
                setFieldValue(key, openModal?.modalValues?.[key] ?? []);
            }
            // else if(key === 'command' && !openModal?.modalValues?.command){
            //     let ffmpegCommand =  `/usr/local/bin/${values.name} \n
            //      -nostats \n
            //      -hide_banner \n
            //      -hwaccel_device 0 \n
            //      -frame_drop_threshold -1.1 \n
            //      -hwaccel cuvid \n
            //      -deint 2 \n
            //      -drop_second_field 1 \n
            //      -i '${values.input}' -y \n
            //      `
            //     ffmpegCommand += values?.resolution?.reduce((acc, item)=>{ 
            //         acc += `\n-vf scale_npp='${item.resolution}' \n 
            //         -c:v h264_nvenc \n 
            //         -c:a aac -level 4.0 \n 
            //         -colorspace bt709 \n 
            //         -color_trc bt709 \n 
            //         -color_primaries bt709 \n 
            //         -color_range tv \n 
            //         -preset fast \n 
            //         -profile:v baseline \n 
            //         -b:v ${item.bitrate} \n 
            //         -sn -start_number 1 \n 
            //         -hls_time 6 \n 
            //         -hls_list_size 6 \n 
            //         -hls_flags delete_segments \n 
            //         -f hls /ramdisk/${values.name}/${item.resolution}/index.m3u8 \n 
            //         `
            //         return acc
            //     },'')
            //     const arr = ffmpegCommand.split('\n').filter(item=>item.trim())
            //     setFieldValue('command', arr.join('\n'))
            // }
            else {
                setFieldValue(key, openModal?.modalValues?.[key] ?? initValues[key]);
            }
        });
        setWithCmd(openModal?.modalValues?.withCmd ?? false)
        setInputs(openModal?.modalValues?.inputs ?? [emptyInput])

    }, [openModal?.bool]);

    const handleRemoveLine = (i) => {
        const arr = values?.command.split('\n')
        arr.splice(i, 1)
        setFieldValue('command', arr.join('\n'))
    }

    const handleAddLine = (i) => {
        const arr = values?.command.split('\n')
        arr.splice(i + 1, 0, ' ')
        setFieldValue('command', arr.join('\n'))
    }

    const handleCommandChange = (val, i) => {
        const arr = values?.command.split('\n')
        arr[i] = val
        setFieldValue('command', arr.join('\n'))
    }

    const willDisableAdvancedMode = !checkPermission([AdvancedModePermission], 'streamer')

    const willDisable = (i) => {
        const arr = values?.command.split('\n')
        const disabledCommands = [`-y -i '${values?.input}'`, `-f hls /streams/${values.name}/%v/index.m3u8`]
        const disabledIndexes = [0]
        return !willDisableAdvancedMode && (disabledIndexes.includes(i) || (disabledCommands.includes(arr[i]?.trim())))
    }

    // console.log(inputs);

    return (
        <MDBox>
            <Grid rowSpacing={2} container>
                <Grid item xs={3}>
                    {t("Streamer")}:
                </Grid>
                <Grid item xs={9}>
                    <MDSelectMui
                        value={selectedServer}
                        error={!selectedServer}
                        onChange={handleChangeSelectedServer}
                        labelKey={"name"}
                        valueKey="_id"
                        options={serversList}

                    />
                </Grid>
                <Grid item xs={3}>
                    {t('Name')}:
                </Grid>
                <Grid item xs={9}>
                    <MDInput
                        fullWidth
                        value={values.name}
                        id="name"
                        name="name"
                        error={touched.name && errors.name}
                        helperText={touched.name && errors.name}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    {t('Source')}:
                </Grid>
                <Grid item xs={9}>
                    <WithPermission disable={true} permissionsList={[EditSourcePermission]} permissionKey={'streamer'} >
                        {inputs.map((e, i, arr) =>
                            <Grid container>
                                <Grid item xs={9}>
                                    <MDInput
                                        fullWidth
                                        disabled={!checkPermission([EditSourcePermission], 'streamer')}
                                        value={e.input}
                                        onChange={(e) => { handleInputsChange(e, i) }}
                                    // error={touched.input?.[i] && errors.input?.[i]}
                                    // helperText={touched.input?.[i] && errors.input?.[i]}
                                    />
                                </Grid>
                                <Grid item xs={3} sx={{ display: 'flex' }}>
                                    <MDSelect
                                        size={"small"}
                                        disabled={!checkPermission([EditSourcePermission], 'streamer')}
                                        value={{
                                            label: inputs[i]?.resolution.name,
                                            name: inputs[i]?.resolution.name,
                                            value: inputs[i]?.resolution.id,
                                        }}
                                        onChange={(ids) => { handleResolution(ids, i) }}
                                        getOptionDisabled={(option) => option.disabled}
                                        options={resolutionsList.map((res, i) => ({
                                            label: res.name,
                                            name: res.name,
                                            value: res.id,
                                            disabled: inputs.some((item) => (item?.resolution?.id === res?.id))
                                        }))}
                                        getOptionLabel={(option) => option.name || ""}
                                        isOptionEqualToValue={(option, value) => { return option.value === value.value }}
                                    />

                                    {i === arr.length - 1 && <IconButton disabled={!checkPermission([EditSourcePermission], 'streamer')} onClick={handleAddInputUrl} >
                                        <AddOutlined />
                                    </IconButton>}
                                    {arr.length > 1 && <IconButton disabled={!checkPermission([EditSourcePermission], 'streamer')} onClick={() => { handleRemoveInputUrl(i) }} >
                                        <Remove />
                                    </IconButton>}
                                </Grid>
                            </Grid>)}
                    </WithPermission>
                </Grid>
                {/* <Grid item xs={3}>
                        Resolutions:
                    </Grid>
                    <Grid item xs={9}>
                    <MDSelectMui
                        // disabled={withCmd}
                        label="select resolutions"
                        value={selectedResIds}
                        error={!!touched.resolution && !!errors.resolution}
                        helperText={touched.resolution && errors.resolution}
                        onChange={handleResolution}
                        options={resolutionsList.map((res, i) => ({
                            label: res.resolution,
                            value: res.id,
                        }))}
                        
                    />
                    </Grid> */}
                <Grid item xs={3}>
                    {t('FFmpeg')}:
                </Grid>
                <Grid item xs={6}>
                    <FormGroup>
                        <WithPermission disable={true} permissionsList={[AdvancedModePermission]} permissionKey={'streamer'} >
                            <FormControlLabel
                                control={
                                    <Switch disabled={willDisableAdvancedMode} color="primary" checked={withCmd} onChange={(e) => setWithCmd(e.target.checked)} />
                                }
                                label={t("Advanced_mode")}
                                labelPlacement="end"
                                checked={withCmd}
                            />
                            {withCmd && <FormHelperText error filled>{t('own_risk_TEXT')} <br /> <strong> *{t('Important')}* </strong> <br /> {t('own_risk_suggest')}</FormHelperText>}
                        </WithPermission>
                    </FormGroup>
                </Grid>
                <Grid item xs={12}>
                    {withCmd ?
                        <WithPermission disable={true} permissionsList={[AdvancedModePermission]} permissionKey={'streamer'} >

                            <MDBox className={styles.inputsCont}>
                                {values?.command.split('\n').map((item, i) => (
                                    <div className={styles.inputCont}>
                                        <div className={styles.toolsLeft} >
                                            <button disabled={willDisable(i)} onClick={() => { handleRemoveLine(i) }} className={styles.delete}><CloseRounded /></button>
                                            <span className={styles.index}>{i + 1}</span>
                                            <button onClick={() => { handleAddLine(i) }} className={styles.add}><AddOutlined /></button>
                                        </div>
                                        <input disabled={willDisable(i)} key={item} className={styles.input} defaultValue={item} onBlur={(e) => handleCommandChange(e.target.value, i)} />
                                    </div>
                                ))}

                            </MDBox>
                        </WithPermission>

                        :
                        <textarea
                            disabled
                            rows={15}
                            style={{ width: '100%' }}
                            value={values.command}
                            id="command"
                            name="command"
                            error={touched.command && errors.command}
                            helperText={touched.command && errors.command}
                            onChange={handleChange}
                        />}
                </Grid>
            </Grid>
            <MDBox sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                {(handleCancel || setOpenModal) && <MDButton variant="outlined" color="info" onClick={() => handleCancel ? handleCancel() : setOpenModal?.({ bool: false })}>
                    {t('Close')}
                </MDButton>}
                <MDButton variant="gradient" color="info" onClick={handleSubmit}>
                    {openModal?.isUpdate ? t('streams.update') : t('streams.create')}
                </MDButton>
            </MDBox>
        </MDBox>
    );
}
