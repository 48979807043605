import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import colors from 'assets/theme/base/colors';
import { getVideoDuration } from "utils/helpers";
import MDButton from "components/MDButton";
import { addNotification } from "redux/actions/notifications";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Tooltip } from "@mui/material";
import { api } from "api/config";
import { updateVODStatus } from "redux/actions/VOD";
import { finishVODStatus } from "redux/actions/VOD";
import MDTypography from "components/MDTypography";
import { convertBytes } from "utils/helpers";
import { useTranslation } from "react-i18next";
import { getToken } from "utils";
import { apiAuth } from "api/config";
import { getVOD } from "redux/actions/VOD";

const UploadProgress = ({ serverInfo, name, vodInfo, setVodInfo, setMovieUploaded, url, movieUploaded, title, onDelete, setOpenModal, openModal, openInfoModal, setOpenInfoModal }) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [uploadProgress, setUploadProgress] = useState(0);
    const [transcodeProgress, setTranscodeProgress] = useState(0);
    const activeVods = useSelector(store => store?.VOD?.activeVods)
    const activeVod = activeVods.find(item => item._id === openModal?.modalValues?._id)
    // const [duration, setDuration] = useState(null)
    const duration = useRef(null)
    const [deleting, setDeleting] = useState(false)
    const [haveMovie, setHaveMovie] = useState(false)
    const [fileName, setFileName] = useState({ name: '', size: null })
    const processUpdate = useRef(null)
    const fileRef = useRef()

    const handleFileInputChange = async (event) => {
        const uploadedFile = event.target.files[0];
        const file = new File([uploadedFile], name, { type: uploadedFile.type });
        setFileName({ name: event.target.files[0]?.name, size: convertBytes(event.target.files[0]?.size) });
        const chunkSize = 1024 * 1024 * 5; // 5MB
        const totalChunks = Math.ceil(file.size / chunkSize);
        let startByte = 0;
        setOpenModal(prev => ({ ...prev, loading: true }))
        for (let i = 1; i <= totalChunks; i++) {
            const endByte = Math.min(startByte + chunkSize, file.size);
            const chunk = file.slice(startByte, endByte);
            await uploadChunk(chunk, totalChunks, i, name);
            const percentComplete = Math.round(Math.min(((chunkSize * i) / file.size) * 100, 100));
            setUploadProgress(percentComplete)
            startByte = endByte;
        }
    };

    async function uploadChunk(chunk, totalChunks, currentChunk, name) {
        const formData = new FormData();
        formData.append('file', chunk);
        formData.append('totalChunks', totalChunks);
        formData.append('currentChunk', currentChunk);
        formData.append('fileName', name);
        try {

            const response = await apiAuth.post(`${serverInfo.host}/upload`, formData);
            if (response.data.fullFile) {
                const data = response.data
                setVodInfo(data?.info)
                setOpenInfoModal(prev => ({ ...prev, title: name, bool: true, info: data?.info }))
                const vofInfo = data?.info?.streams?.find?.(item => item.codec_type === 'video')
                if (vofInfo?.duration) {
                    duration.current = +vofInfo.duration
                } else if (vofInfo?.tags?.DURATION) {
                    try {
                        const [h, m, s] = vofInfo.tags.DURATION.split('.')[0].split(':')
                        duration.current = +h * 3600 + +m * 60 + +s
                    } catch (error) {
                        console.log(error);
                        duration.current = 100
                    }
                }
                setMovieUploaded(true)
                dispatch(addNotification({
                    color: 'success',
                    icon: 'success',
                    title: `Uploaded`,
                    content: `${title} Uploaded successfully`,
                    dateTime: Date.now(),
                }))
                setOpenModal({
                    ...openModal,
                    loading: false,
                    movieUpload: (codecs) => {
                        movieTranscode({ filename: name, codecs })
                    }
                })
            }
        } catch (error) {
            console.log(error);
            await new Promise((resolve) => {
                setTimeout(() => {
                    uploadChunk(chunk, totalChunks, currentChunk, name)
                        .then((res) => {
                            resolve(res)
                        })
                        .catch(err => {
                            console.log(err);
                            api.patch(`/VOD/${openModal?.modalValues?._id}/processUpdate`, { removeProgress: 'null' })
                            dispatch(addNotification({
                                color: 'error',
                                icon: 'error',
                                title: `Upload Error`,
                                content: `${title} an error occuired`,
                                dateTime: Date.now(),
                            }))
                        })
                }, 2000)
            })
        }
    }

    useEffect(() => {
        const videoInfo = vodInfo?.streams?.find?.(item => item.codec_type === 'video')
        if (videoInfo?.duration) {
            duration.current = +videoInfo.duration
        } else if (videoInfo?.tags?.DURATION) {
            try {
                const [h, m, s] = videoInfo.tags.DURATION.split('.')[0].split(':')
                duration.current = +h * 3600 + +m * 60 + +s
            } catch (error) {
                console.log(error);
                duration.current = 100
            }
        }
    }, [vodInfo])

    const movieTranscode = async (body) => {
        setOpenModal(prev => ({ ...prev, loading: true }))
        const { data } = await apiAuth.post(`${serverInfo.host}/transcode`, body)
        api.patch(`/vod/${openModal?.modalValues?._id}/process`, {
            uploadStatus: 100,
            duration: duration.current,
            host: serverInfo.host,
            name: name,
            url: `${serverInfo.host}/transcodeStatus/${name}`
        })
        
        let timeoutId;
        const getStatusOfTranscode = () => {
            apiAuth.get(`${serverInfo.host}/transcodeStatus/${name}`)
                .then((res) => {
                    const durationVideo = Math.round(+duration.current)
                    const durationTranscoded = Math.round(+res.data.length)
                    if (durationTranscoded === 0 || durationVideo === 0 || durationTranscoded < durationVideo) {
                        timeoutId && clearTimeout(timeoutId)
                        timeoutId = setTimeout(async () => {
                            getStatusOfTranscode()
                        }, 5000)
                    }else{
                        dispatch(addNotification({
                            color: 'success',
                            icon: 'success',
                            title: `Transcode completed`,
                            content: `${title} Transcode completed`,
                            dateTime: Date.now(),
                        }))
                        setOpenModal({ bool: false })
                        dispatch(getVOD())
                    }
                })
                .catch(error => {
                    // console.log("Error During Transcoding !!!!!!!", { error });
                    // // api.patch(`/VOD/${openModal?.modalValues?._id}/processUpdate`, null)
                    // dispatch(addNotification({
                    //     color: 'error',
                    //     icon: 'error',
                    //     title: `Upload Error`,
                    //     content: `${title} an error occuired`,
                    //     dateTime: Date.now(),
                    // }))
                })
        }
        getStatusOfTranscode()
    }


    const handleDeleteMovie = async () => {
        try {
            setDeleting(true)
            fileRef.current.value = ""
            if (haveMovie) {
                await apiAuth.delete(`${serverInfo.host}/VOD/${name}`)
            } else if (movieUploaded) {
                await apiAuth.delete(`${serverInfo.host}/VOD/upload/${name}`)
            }
            setVodInfo()
            setMovieUploaded(false)
            setDeleting(false)
            onDelete()
            setTranscodeProgress(0)
            setUploadProgress(0)
            setHaveMovie(false)
            setFileName({ name: '', size: null })
            api.patch(`/VOD/${openModal?.modalValues?._id}/processUpdate`, { removeProgress: true })

        } catch (error) {
            fileRef.current.value = ""
            api.patch(`/VOD/${openModal?.modalValues?._id}/processUpdate`, { removeProgress: true })
            setFileName({ name: '', size: null })
            setVodInfo()
            setMovieUploaded(false)
            setDeleting(false)
            onDelete()
            setTranscodeProgress(0)
            setUploadProgress(0)
            setHaveMovie(false)
        }
    }

    useEffect(() => {
        if (movieUploaded && !openModal?.movieUpload && name) {
            setOpenModal({
                ...openModal,
                movieUpload: (codecs) => {
                    movieTranscode({ filename: name, codecs })
                }
            })
        }
    }, [movieUploaded, openModal])

    // const getTranscodeStatus = async () => {

    //     const { data } = await apiAuth.get(`${serverInfo.host}/transcodeStatus/${name}`)
    //     const maxCount = Math.floor(duration.current / 10)
    //     setTranscodeProgress(Math.floor(data.count * 100 / maxCount))
    //     dispatch(updateVODStatus({
    //         _id: openModal.modalValues._id,
    //         uploadStatus: 100,
    //         transcodeStatus: Math.floor(data.count * 100 / maxCount)
    //     }))

    //     if (data.count < maxCount) {
    //         setTimeout(async () => {
    //             await getTranscodeStatus()
    //         }, data.count === 0 ? 600 : 5000)
    //     } else {
    //         dispatch(finishVODStatus(openModal.modalValues._id))
    //         dispatch(addNotification({
    //             color: 'success',
    //             icon: 'success',
    //             title: `Transcode completed`,
    //             content: `${title} Transcode completed`,
    //             dateTime: Date.now(),
    //         }))
    //         setOpenModal({bool: false})
    //     }
    // }

    useEffect(() => {
        const checkData = async () => {
            try {
                const res = await api.get(url)
                if (res) setHaveMovie(true)
            } catch (error) {
                setHaveMovie(false)
            }
        }
        if (url) {
            checkData()
        }

    }, [url])

    if (!name || !openModal?.modalValues?._id) {
        return null
    }

    return (
        <>

            {deleting ?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <ClipLoader color={colors.error.main} />
                    <div>Deleting ...</div>
                </div>
                :
                <div>
                    {
                        // !!progress && progress<100 &&
                        movieUploaded ? <div>
                            <div style={{ textAlign: "center" }} > {t("Uploaded_successfully")} </div>
                            <progress style={{ width: '100%' }} value={100} max="100" />
                        </div> :
                            <div>
                                <div style={{ textAlign: "center" }} > {+uploadProgress || (+activeVod?.uploadStatus && +activeVod?.uploadStatus < 100) ? `${t("Uploading")} ${+uploadProgress || +activeVod?.uploadStatus || 0}%` : haveMovie ? t("Uploaded_successfully") : `${t("Uploading")} 0%`} </div>
                                <progress style={{ width: '100%' }} value={uploadProgress || ((haveMovie ? 100 : 0))} max="100" />
                            </div>
                    }
                    {
                        // progress>=100 && transcodeProgress<100 && 
                        <div>
                            <div style={{ textAlign: "center" }} > {+activeVod?.transcodeStatus && +activeVod?.transcodeStatus < 100 ? `${t("Transcoding")} ${+activeVod?.transcodeStatus || 0}%` : haveMovie || +activeVod?.transcodeStatus >= 100 ? t("Transcoded_successfully") : `${t("Transcoding")} 0%`} </div>
                            <progress style={{ width: '100%' }} value={+activeVod?.transcodeStatus || (haveMovie ? 100 : 0)} max="100" />
                        </div>}
                </div>}
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: '10px' }}>
                {/* {movieUploaded && <span style={{ color: colors.success.main }} >Uploaded successfully!</span>}  */}
                {/* {transcodeProgress>=100 && <span style={{ color: colors.success.main }} >Transcoded successfully!</span>}  */}

                <Tooltip title={(movieUploaded || haveMovie) ? t('dblClick') : ''} >
                    <MDButton disabled={!(movieUploaded || haveMovie)} variant="outlined" color="error" onDoubleClick={handleDeleteMovie}>
                        {t("Delete")}
                    </MDButton>
                </Tooltip>
                <MDButton disabled={movieUploaded || haveMovie} variant="gradient" color="info" component="label" >
                    {t("Upload")}
                    <input ref={fileRef} hidden accept="video/*,video/x-matroska" type="file" onChange={handleFileInputChange} />
                </MDButton>
                {vodInfo && <MDButton disabled={haveMovie} variant="outlined" color="info" onClick={() => setOpenInfoModal(prev => ({ ...prev, title: name, bool: true, info: vodInfo }))} >
                    {t("Content")}
                </MDButton>}

                {fileName.name && <MDTypography style={{ position: 'absolute', bottom: '-20px', right: '0px' }} variant='caption'>
                    {`${fileName.name} - ${fileName.size}`}
                </MDTypography>}
            </div>
        </>
    );
};

export default UploadProgress;
