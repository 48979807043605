import { diffWords } from "diff";
import i18n from "i18n";
import moment from "moment";

export const getSingleItemByAnOption = (arr, key, val) => {
  return arr.find((item) => item[key] === val);
};

export const getAvg = (nums) => {
  const total = nums.reduce((acc, c) => acc + c, 0);
  return total / nums.length;
}


export const convertBytes = (x, deltaL = 0) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = deltaL, n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const copyToClipboard = (str) => {
  navigator.clipboard.writeText(str);
}

export const parse = str => {
  if (!str) return {}
  // example "20220317014000 +0400"
  // if(!/^(\d){8}$/.test(str)) return "invalid date";
  let Y = str.substr(0, 4),
    M = str.substr(4, 2),
    D = str.substr(6, 2),
    h = str.substr(8, 2),
    m = str.substr(10, 2),
    s = str.substr(12, 2),
    timezone = str.substr(14);
  return { date: new Date(Y, M, D, h, m, s), timezone, Y, M, D, h, m, s };
};

export const generateM3U8URL = (options) => {
  return `${options.baseArchiver}/archive/indexByDate?startTime=${options.startTime}&endTime=${options.endTime}&day=${options.day}&archiveName=${options.archiveName}&date=${options.date}&userOffset=${options.offset}`
}

export const shiftEpgTime = (epgTime, shiftHours) => {
  if (!epgTime) return
  const { Y, M, D, h, m, s } = parse(epgTime)
  const offset = new Date().getTimezoneOffset()
  const offsetHours = shiftHours ? shiftHours : (offset / 60)

  let byMoment = `${Y}${M}${D}${h}${m}${s}`;
  if (offsetHours < 0) {
    byMoment = moment(`${Y}${M}${D}T${h}${m}${s}`).add(Math.abs(offsetHours), 'hours').format('YYYYMMDDHHmmss')
  } else if (offsetHours > 0) {
    byMoment = moment(`${Y}${M}${D}T${h}${m}${s}`).subtract(Math.abs(offsetHours), 'hours').format('YYYYMMDDHHmmss')
  }
  return byMoment;

}

export const getVideoDuration = (file, setter) => {
  const video = document.createElement('video');
  video.preload = 'metadata';

  video.onloadedmetadata = function () {
    window.URL.revokeObjectURL(video.src);
    const duration = video.duration;
    setter(duration)
  }

  video.src = URL.createObjectURL(file);
};

export const generateFormDataFromObject = (body) => {
  const formData = new FormData();
  for (let key in body) {
    if (body[key] instanceof File) {
      formData.append(key, body[key], body[key].name)
    } else if (body[key] instanceof Object) {
      if (body[key].hasOwnProperty('_id')) {
        delete body[key]._id
      }
      if (body[key].hasOwnProperty('__v')) {
        delete body[key].__v
      }
      formData.append(key, JSON.stringify(body[key]))
    } else {
      formData.append(key, body[key])
    }
  }
  return formData
}

export const objectToFormDataRecursively = function(obj, form, namespace) {
    
  let fd = form || new FormData();
  let formKey;
  
  for(let property in obj) {
    if(obj.hasOwnProperty(property)) {
      if(namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }
      if(obj[property] instanceof File){
        fd.append(formKey, obj[property], obj[property].name)
      }else if(typeof obj[property] === 'object') {
        if (obj[property].hasOwnProperty('_id')) {
          delete obj[property]._id
        }
        if (obj[property].hasOwnProperty('__v')) {
          delete obj[property].__v
        }
        objectToFormDataRecursively(obj[property], fd, property);
      } else {
        fd.append(formKey, obj[property]);
      }
      
    }
  }
  
  return fd;
    
};

export const addThousandSeparator = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const truncateString = (str, maxLength) => {
  if (!str) {
    return ''
  }
  if (str.length <= maxLength) {
    return str;
  }

  return str.substring(0, maxLength) + "...";
}

export const isVowel = (symbol) => {
  const russianVowels = ['а', 'е', 'ё', 'и', 'о', 'у', 'ы', 'э', 'ю', 'я'];
  const englishVowels = ['a', 'e', 'i', 'o', 'u'];
  const armenianVowels = ['ա', 'ե', 'է', 'ը', 'ի', 'ո', 'ւ', 'օ', 'և', 'եվ'];

  return (
    russianVowels.includes(symbol.toLowerCase()) ||
    englishVowels.includes(symbol.toLowerCase()) ||
    armenianVowels.includes(symbol)
  )
}


export const objectToString = (obj) => {
  if(!obj) return null
  let result = "\n";
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    const isNan = Number.isNaN(parseInt(keys[i]))
    result += isNan ? `${keys[i]}: ` : '';
    if (obj[keys[i]] && typeof obj[keys[i]] === "object") {
      const rep = "\n  "
      result += objectToString(obj[keys[i]])?.replace(/\n/g, rep);
    } else {
      result += JSON.stringify(obj[keys[i]]);
    }
    if (i < keys.length - 1) {
      result +=  ", \n";
    }
  }
  result += "";
  return result.replaceAll('"', '');
}

// export const highlightChanges = (str1, str2) => {
//   // Use diffLines from the diff library to get the differences between the strings
//   const diff = diffLines(str1, str2);

//   // Build the HTML string
//   let html = "";
//   diff.forEach((line) => {
//     if (line.added) {
//       html += `<p style='color:green'>${line.value}</p>`;
//     } else if (line.removed) {
//       html += `<p style='color:red'>${line.value}</p>`;
//     } else {
//       html += `<p>${line.value}</p>`;
//     }
//   });
//   html += "";

//   return html;
// }

export const highlightChanges = (str1, str2) => {
    // Use diffChars from the diff library to get the differences between the strings
    const diff = diffWords(str1, str2);
  
    // Build the HTML string
    let html = "";
    diff.forEach((part) => {
      const color = part.added ? "green" : part.removed ? "red" : "inherit";
      if(part.added){
        html += `<span> => </span>`;
      }
      html += `<span style='color:${color}'>${part.value}</span>`;
    });
    html += "";
  
    return html;
  }


  
  export const getDayStartTimestamp = (timestamp) => {
    const date = new Date(timestamp); 
    const res = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  
    return res.getTime(); 
  }

  export const getDayEndTimestamp = (timestamp) => {
    const date = new Date(timestamp); 
    const res = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
  
    return res.getTime(); 
  }
  
  
  export const debounce = (func, wait, immediate = false) => {
    let timeout;
  
    return function(...args) {
      const context = this;
  
      const later = function() {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
  
      const callNow = immediate && !timeout;
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
  
      if (callNow) {
        func.apply(context, args);
      }
    };
  }
  

  export const getNestedKey = (obj, nestedKeyStr) => {
    const split = nestedKeyStr.split(".")
      if(split.length > 1 && typeof (obj[split[0]]) === "object" && obj[split[0]] !== null){
        return getNestedKey(obj[split[0]], split.slice(1).join("."))
      }else{
        return obj[split[0]]
      }
  }


  export const generateDays = (num, variant, labelFormat = 'DD/MM ddd') => {
    // variant = -1 means past
    // variant = 0 means bidirectional
    // variant = 1 means future
    const days = [
      {
        epgValue: moment().format('YYYYMMDD'),
        label: moment().format(labelFormat),
        day: moment().format('DD'),
      },
    ];
    for (let i = 1; i < num; i++) {
      if(variant === 1 || variant === 0){
        days.push({
          epgValue: moment().add(i, 'days').format('YYYYMMDD'),
          label: moment().add(i, 'days').format(labelFormat),
          day: moment().add(i, 'days').format('DD'),
        });
      }
      if(variant === -1 || variant === 0) {
        days.unshift({
          epgValue: moment().subtract(i, 'days').format('YYYYMMDD'),
          label: moment().subtract(i, 'days').format(labelFormat),
          day: moment().subtract(i, 'days').format('DD'),
        });
      }
    }
    return days;
  };


  export function formatDuration(ms) {
    const msInSecond = 1000;
    const msInMinute = 60 * msInSecond;
    const msInHour = 60 * msInMinute;

    if (ms >= msInHour) {
        const hours = (ms / msInHour).toFixed(1);
        return `${hours} ${i18n.t('hours')}`;
    } else if (ms >= msInMinute) {
        const minutes = (ms / msInMinute).toFixed(1);
        return `${minutes} ${i18n.t('minutes')}`;
    } else {
        const seconds = (ms / msInSecond).toFixed(1);
        return `${seconds} ${i18n.t('seconds')}`;
    }
}


export const colorByPercent = (percent) => {
  if (percent < 20) {
      return 'darkred'
  } else if (percent < 50) {
      return 'orange'
  }
  return 'lightgreen'
}

export const valueOrNA = (value) => {
  return value || "N/A"
}